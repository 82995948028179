<template>
  <!-- <div class="app app-body flex-row align-items-center"> -->
  <div class="app flex-row align-items-center">
    <div id="spinner" class="justify-content-center align-items-center"
        :style="{ height: spinnerHeight + 'px', 
          width: spinnerWidth + 'px' }" 
        v-if="spinnerVisible">
      <b-spinner id="spinner-spin" label="Loading...">
      </b-spinner>
    </div>
    <!-- <app-procesando 
      :procesando= 'procesando'>
    </app-procesando> -->
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="9">
          <b-card-group>
            <b-card no-body class="p-4 login-card">
              <b-card-body>
                <b-form>
                  <h1>Iniciar Sesión</h1>
                  <p class="text-muted">Ingrese sus credenciales de acceso</p>
                  <div class="credenciales">
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <i class="icon-user"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" class="form-control" 
                        v-model="credenciales.username" 
                        placeholder="Username"/>
                    </b-input-group>
                    <b-input-group class="mb-4">
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <i class="icon-lock"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="password" class="form-control" 
                        v-model="credenciales.password" 
                        placeholder="Contraseña"
                        @keyup.native.enter="login" />
                    </b-input-group>
                  </div>
                  <b-row>
                    <b-col cols="6">
                      <b-button variant="primary" 
                        class="px-4"
                        @click.prevent="login">Iniciar Sesión</b-button>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0"
                        @click.prevent="forgot">Olvidé mi contraseña</b-button>                      
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <b-card no-body class="p-4 login-card">
              <!-- <b-card-body>
                <b-form>
                  <h1>Google</h1>
                  <p class="text-muted">Inicie sesión a través de su cuenta de correo</p>
                  <div class="credenciales"></div>
                  <b-row>
                    <b-col cols="6">
                      <button v-google-signin-button="clientId" class="btn px-4 btn-primary"> Iniciar sesión</button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body> -->
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>
  </div>
</template>

<script>
// import Procesando from '../shared/spinnerProcesando.vue';

export default {
  name: 'Login',
  components: {
    // appProcesando: Procesando
  },
  data () {
    return {
      credenciales: {
        username: '',
        password: ''
      },
    }
  },
  computed: {
    spinnerVisible: {
      get: function () {
        return this.$store.getters['spinnerProcesando/procesando'];
      },
      set: function (newValue) {
      }
    },
    spinnerHeight: {
      get: function () {
        return this.$store.getters['spinnerProcesando/height'];
      },
      set: function (newValue) {
      }
    },
    spinnerWidth: {
      get: function () {
        return this.$store.getters['spinnerProcesando/width'];
      },
      set: function (newValue) {
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    login() {
      this.showSpinner();
      this.$store.dispatch('user/login', this.credenciales)
        .then(() => this.$router.push({ name: 'LoginEmpresa' }))
        .catch( error => this.showErrorMsj (error))
        .finally(() => this.hideSpinner());

    },
    forgot (){
      // this.$router.push({ name: 'ForgotPassword' });
    }    
  },
}
</script>

<style>
  .credenciales {
    min-height: 120px;
  }
</style>